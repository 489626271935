import * as React from "react"

// Crypto
import crypto from "crypto-js"

// Firebase
import { getFirebase, generateAuthCode } from "../js/firebase/firebase"

// SEO
import SEO from "../components/seo/seo"

// Theme
import {
  LLayout,
  LButton,
  LRow,
  LCol,
  LTitle,
  LText,
  LContent,
  LFPinCode,
  LNavEmptyFloating,
  LFImageUpload,
  LFooterLove,
  FFullPage,
  FFullPageContent,
} from "../components/fs_theme"

// Style
import styled from "styled-components"

// CSS
import "antd/dist/antd.less"

export default class FUpload_Page extends React.Component {
  constructor(props) {
    super(props)

    console.log(`id: ${props.bucketId}`)

    // Get the bucket id
    this.bucketId = props.bucketId

    // Set component properties
    this.code = null
    this.pinCode = null
    this.codeListener = null
    this.codeListenerRef = null
    this.bucketListener = null
    this.bucketListenerRef = null
    this.signinInProgress = false

    // Set state properties
    this.state = {
      isContinueDisabled: true,
      isContinueLoading: false,
      isValidPin: true,
      isUserSignedIn: false,
      didRetrieveCode: false,
      userId: null,
      ownerId: null,
      contentType: null,
      contentId: null,
      bucketRef: null,
    }
  }

  componentDidMount() {
    // Load Firebase Lazily

    const lazyApp = import("firebase/app")
    const lazyDatabase = import("firebase/database")
    const lazyAuth = import("firebase/auth")

    console.log("LOADED UPLOAD")

    Promise.all([lazyApp, lazyDatabase, lazyAuth]).then(([firebase]) => {
      const auth = getFirebase(firebase).auth()
      const database = getFirebase(firebase).database()
      this.auth = auth
      this.database = database
      this.onReady()
      console.log("UPLOAD READY")
    })
  }

  // Libraries are initialized and component is ready.
  onReady = () => {
    // Ensure bucket is valid
    if (!this.bucketId) {
      // TODO: Show empty state
      console.log("Missing parameters. Show empty state")
      return
    }

    // Listen for user states
    this.auth.onAuthStateChanged(user => {
      console.log("Auth state did change.")

      // Check if valid user
      if (user && !user.isAnonymous) {
        console.log("Updating user is signed in.")

        // Update state
        this.setState({ isUserSignedIn: true, userId: user.uid })
        console.log("Updating user signed in true")

        // Fetch Bucket
        this.getUserBucket(this.bucketId)
      } else {
        console.log(`User is not signed in. Signing in...`)
        if (!this.signinInProgress) {
          this.signinInProgress = true
          this.setState({
            isUserSignedIn: false,
            userId: null,
          })

          // Sign in
          this.signInUser()
        }
      }
    })
  }

  // Sign in user.
  signInUser = () => {
    if (!this.bucketId || this.auth.currentUser) {
      console.log("Missing the bucketId, or the user is already signed in.")
      return
    }

    this.getUserBucket(this.bucketId)
  }

  getUserBucket = bucketId => {
    // 1. Get user id for requested bucket
    this.database
      .ref("fs_upload_bucket")
      .child(bucketId)
      .child("info")
      .child("userId")
      .once("value")
      .then(snapshot => {
        // Get user id
        const userId = snapshot.val()

        // Ensure that we have a valid user id
        if (!userId) {
          console.log("Unable to get user id for this bucket")
          return
        }

        // Check if user is signed in
        if (this.auth.currentUser) {
          if (this.auth.currentUser.uid !== userId) {
            console.log("This is a different user. Signing out...")
            this.auth.signOut()
          } else {
            console.log("User can access this bucket. Fetching contents...")
            this.getUploadBucket(this.bucketId)
          }
          return
        }

        // Get auth code for user
        this.getAuthCode(userId)
      })
      .catch(error => {
        console.log("Error getting userId for requested bucket", error)
      })
  }

  // Get authentication code from client to decrypt custom token
  getAuthCode = userId => {
    // Ensure that we have a valid user id
    if (!userId) {
      console.log("Unable to get auth code for this bucket. Invalid user id.")
      return
    }

    // 3. Get a valid encrypted token and update visiblity
    let codeRef = this.database
      .ref("fs_auth_code")
      .child(userId)
      .child("code")

    let codeListener = codeRef.on("value", snapshot => {
      const authCode = snapshot.val()

      // Ensure we got a valid auth code
      if (!authCode) {
        console.log("Unable to get auth code. Generating new code...")
        // Generate new code
        generateAuthCode(userId)
        return
      }

      console.log("Got new auth code!")

      // Update code
      this.onCode(authCode)
    })

    // Update auth visiblity to ensure the client will show the pin
    this.database
      .ref("fs_auth_code")
      .child(userId)
      .update({ isVisible: true })

    // Update listeners to remove later
    this.codeListener = codeListener
    this.codeListenerRef = codeRef
  }

  // Remove listener when completed
  removeCodeListener = () => {
    if (this.codeListenerRef && this.codeListener) {
      this.codeListenerRef.off("value", this.codeListener)
      this.codeListenerRef = null
      this.codeListener = null
    }
  }

  onCode = code => {
    // Save code to decrypt later
    this.code = code

    // Update state
    if (!this.state.didRetrieveCode) {
      this.setState({ didRetrieveCode: true })
    }
  }

  onPinCode = code => {
    // Save pin code to decrypt code later
    this.pinCode = code

    // Update state
    if (code) {
      this.setState({ isContinueDisabled: false })
    } else {
      this.setState({ isContinueDisabled: true })
    }

    this.setState({ isValidPin: true })
  }

  onConintue = () => {
    if (this.pinCode && this.code) {
      // Start loading
      this.setState({ isContinueLoading: true })

      // Decrypt token and sign in user
      try {
        const tokenBytes = crypto.AES.decrypt(this.code, this.pinCode)
        const token = tokenBytes.toString(crypto.enc.Utf8)
        this.signInUserWithToken(token)
      } catch (error) {
        console.log(error)
        // TODO: Show error toast
        this.setState({ isValidPin: false })
        this.setState({ isContinueLoading: false })
      }
    }
  }

  signInUserWithToken = token => {
    // Sign in user with token
    this.auth
      .signInWithCustomToken(token)
      .catch(error => {
        console.log("Unable to sign in user with token", error)
        this.setState({ isContinueLoading: false })
      })
      .then(() => {
        // Update state and remove listener.
        this.setState({ isUserSignedIn: true, isContinueLoading: false })
        this.removeCodeListener()
      })
  }

  // Reset pin code to reenter
  resetPin = () => {
    // TODO: Add clear field and refoucus to first input
    this.pinCode = null
    this.setState({ isContinueDisabled: true })
  }

  // Get upload bucket
  getUploadBucket = bucketId => {
    const bucketRef = this.database.ref("fs_upload_bucket").child(bucketId)
    const bucketInfoRef = bucketRef.child("info")

    // Set listnerRef to unregister later
    this.bucketListenerRef = bucketInfoRef

    // Get inital bucket
    bucketInfoRef
      .once("value")
      .then(snapshot => {
        if (!snapshot) {
          console.log("Unable to get bucket.")
          return
        }

        // Get bucket
        const bucketInfo = snapshot.val()

        if (bucketInfo) {
          // Update bucket
          this.onBucket(bucketInfo)

          console.log(`Got bucket. Updating state: ${bucketInfo.contentType}`)
          this.setState({
            contentType: bucketInfo.contentType,
            contentId: bucketInfo.contentId,
            ownerId: bucketInfo.ownerId,
            bucketRef: bucketRef,
          })
        } else {
          this.onInvalidBucket()
        }

        // Listen for removable events
        this.bucketListener = bucketRef.on("child_removed", snapshot => {
          console.log("This bucket no longer exists. Updating state.")
          this.onInvalidBucket()
        })
      })
      .catch(error => {
        console.log("Error getting requested bucket", error)
        this.onInvalidBucket()
      })
  }

  onBucket = bucket => {
    console.log("Bucket was updated..")
    // TODO: Add extra UI Elementss
  }

  onInvalidBucket = () => {
    this.setState({
      contentType: null,
      contentId: null,
      bucketRef: null,
    })

    // Remove bucket listner
    this.removeBucketListener()
  }

  removeBucketListener() {
    if (this.bucketListenerRef && this.bucketListener) {
      this.bucketListenerRef.off("value", this.bucketListener)
      this.bucketListenerRef = null
      this.bucketListener = null
    }
  }

  render() {
    const {
      isContinueDisabled,
      isContinueLoading,
      isUserSignedIn,
      isValidPin,
      didRetrieveCode,
      userId,
      ownerId,
      contentType,
      contentId,
      bucketRef,
    } = this.state

    return (
      <div>
        <SEO title="Upload" />
        <LLayout>
          <LNavEmptyFloating />
          <LLayout>
            <LContent>
              <FFullPage>
                <FFullPageContent>
                  <LRow>
                    <LCol span={4} />
                    <LCol span={16} align="center">
                      <div
                        hidden={
                          isUserSignedIn ||
                          (!isUserSignedIn && !didRetrieveCode)
                        }
                      >
                        <FTitle level={3}>Enter Pin Code</FTitle>
                        <FText>
                          The pin code should be display in the Fonoshoot app
                          where this link was generated.
                        </FText>
                        <br />
                        <FPinContainer>
                          <LFPinCode
                            onResult={this.onPinCode}
                            isValidPin={isValidPin}
                          />
                        </FPinContainer>
                        <br />
                        <LButton
                          type="primary"
                          disabled={isContinueDisabled}
                          loading={isContinueLoading}
                          onClick={this.onConintue}
                        >
                          Continue
                        </LButton>
                      </div>
                      <FUploadGroup hidden={!isUserSignedIn}>
                        <br />
                        <FUploadContainer>
                          {contentType && userId && bucketRef ? (
                            <div>
                              <LFImageUpload
                                contentType={contentType}
                                contentId={contentId}
                                userId={userId}
                                ownerId={ownerId}
                                bucketRef={bucketRef}
                                onRequestSave={id =>
                                  console.log(`Document Stored: ${id}`)
                                }
                                onRequestClear={() =>
                                  console.log("Document Cleared")
                                }
                                defaultFiles={[]}
                              />
                              <br />
                              <br />
                              <FText> Bucket Id: {this.bucketId} </FText>
                            </div>
                          ) : null}
                        </FUploadContainer>
                      </FUploadGroup>
                    </LCol>
                    <LCol span={4} />
                  </LRow>
                </FFullPageContent>
              </FFullPage>
            </LContent>
          </LLayout>
          <LFooterLove />
        </LLayout>
      </div>
    )
  }
}

// Styled components

const FUploadContainer = styled.div`
  &&& {
    margin-top: 10px;
    display: inline-block;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
`

const FUploadGroup = styled.div`
  &&& {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const FPinContainer = styled.div`
  &&& {
    display: inline-block;
    max-width: 75%;
    padding: 20px 0px 25px 0px;
    @media (max-width: 450px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 100%;
    }

    @media (min-width: 360px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 65%;
    }

    @media (min-width: 1000px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 50%;
    }
  }
`

const FText = styled(LText)`
  &&& {
    max-width: 75%;
    display: inline-block;
    @media (max-width: 360px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 100%;
    }
  }
`

const FTitle = styled(LTitle)`
  &&& {
    color: black;
  }
`
